import { defineStore } from 'pinia';
import ajax from '@/api/api-manager';

// 메인 홈페이지 최상단 메인 배너 리스트
export const SearchStore = defineStore('Search', {
	state: () => ({
		//스토어 내에 기본값 생성
		itemList: [],
		genre: 'ALL',
		page: 1,
		perPage: null,
		totalPage: null,
		totalItemCount: null,
		search_modal: false,
		not_found: false,
		genre_total: null,
	}),
	getters: {},

	actions: {
		// [메인] 장르별 작품 리스트 전용
		getHomeGenreItems() {
			const params = {
				genre: this.genre,
			};
			ajax('home/byGenre', params).then((response) => {
				this.itemList = response.data.data;
			});
		},
		// [서브] 장르별 작품 리스트 페이지 전용
		getSubGenreItems() {
			const params = {
				genre: this.genre,
				page: this.page,
				search: SearchTextStore().search_text,
			};
			ajax('home/bySearch', params).then((response) => {
				console.log(response);
				if (response.data.data.pagingList.length === 0) {
					this.not_found = true;
				} else if (response.data.data.pagingList.length > 0) {
					this.not_found = false;
				}
				this.genre_total = response.data.data.genreTotal;
				this.itemList = response.data.data.pagingList;
				this.perPage = response.data.perpage;
				this.totalPage = response.data.totalpage;
				this.totalItemCount = response.data.totalrecordcount;
			});
		},
		// 장르탭에서 장르 변경 시 실행됨
		setGenre(genre) {
			this.page = 1;
			this.genre = genre;
		},
	},
});
export const SearchTextStore = defineStore('SearchText', {
	state: () => ({
		//스토어 내에 기본값 생성
		search_text: '',
	}),
	getters: {},

	actions: {},
	persist: {
		enabled: true,
	},
});
