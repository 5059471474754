/* eslint-disable prettier/prettier */
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home'),
        meta: {
            title: '홈',
        },
    },
    {
        path: '/sub',
        name: 'Sub',
        component: () => import('@/views/Sub'),
        meta: {
            wrap: 'sub'
        },
        children: [
            {
                path: 'genre',
                name: 'Genre',
                component: () => import('@/components/sub/Genre'),
                meta: {
                    title: '장르별 작품 리스트',
                },
            },
            {
                path: 'new',
                name: 'New',
                component: () => import('@/components/sub/New'),
                meta: {    
                    title: '신작 작품 리스트',
                },
            },
            {
                path: 'ranking',
                name: 'Ranking',
                component: () => import('@/components/sub/Ranking'),
                meta: {
                    title: '랭킹',
                },
            },
            {
                path: 'event',
                name: 'Event',
                component: () => import('@/components/sub/Event'),
                meta: {
                    title: '이벤트',
                },
            },
            {
                path: 'servicecenter',
                name: 'ServiceCenter',
                component: () => import('@/components/sub/ServiceCenter'),
                meta: {
                    title: '고객센터',
                },
            },
        ]
    },
    
    {
        path: '/detail/:idx',
        name: 'ContentDetail',
        component: () => import('@/views/ContentDetail'),
        props: true,
        meta: {
            wrap: 'sub',
        },
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('@/shared-components/Search'),
        meta: {
            title: '검색완료페이지',
            wrap: 'sub',
        },
    },
    {
        path: '/mypage',
        name: 'MyPage',
        component: () => import('@/views/MyPage'),
        meta: {
            title: '내 정보',
            wrap: 'sub',
        },
        children: [
            {
                path: 'account',
                name: 'myAccount',
                component: () => import('@/components/mypage/Account'),
                meta: {
                    title: '내 정보',
                },
            },
            {
                path: 'secession',
                name: 'mySecession',
                component: () => import('@/components/mypage/Secession'),
                meta: {
                    title: '회원 탈퇴',
                },
            },
            {
                path: 'like',
                name: 'myLike',
                component: () => import('@/components/mypage/Like'),
                meta: {
                    title: '좋아요 리스트',
                },
            },
            {
                path: 'content',
                name: 'myContent',
                component: () => import('@/components/mypage/Content'),
                meta: {
                    title: '콘텐츠 보관함',
                },
            },
            {
                path: 'membership',
                name: 'myMembership',
                component: () => import('@/components/mypage/Membership'),
                meta: {
                    title: '나의 요금제 / 회원권 결제',
                },
            },
            {
                path: 'playlist',
                name: 'myPlayList',
                component: () => import('@/components/mypage/PlayList'),
                meta: {
                    title: '나의 재생목록',
                },
            },
            {
                path: 'gift',
                name: 'myGift',
                component: () => import('@/components/mypage/Gift'),
                meta: {
                    title: '내 선물함',
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'LogIn',
        component: () => import('@/views/LogIn'),
        meta: {
            wrap: 'sub',
        },
    },
    {
        path: '/login/naver',
        name: 'Redirect',
        component: () => import('@/components/membership/NaverCallback'),
        meta: {
            title: '리다이렉트',
        },
    },
    {
        path: '/modifypw',
        name: 'ModifyPw',
        component: () => import('@/components/membership/ModifyPw'),
        meta: {
            wrap: 'sub',
        },
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: () => import('@/views/SignUp'),
        meta: {
            wrap: 'sub',
            title: '회원가입'
        },
        // children: [
        //     {
        //         path: 'agree',
        //         name: 'Agree',
        //         component: () => import('@/components/membership/MemberAgree'),
        //         meta: {
        //             wrap: 'sub',
        //             title: '약관동의',
        //         },
        //     },
        //     {
        //         path: 'complete',
        //         name: 'Complete',
        //         component: () => import('@/components/membership/MemberComplete'),
        //         meta: {
        //             wrap: 'sub',
        //             title: '가입완료',
        //         },
        //     },
        //     {
        //         path: 'info',
        //         name: 'Info',
        //         component: () => import('@/components/membership/MemberSignup'),
        //         meta: {
        //             wrap: 'sub',
        //             title: '가입정보 입력',
        //         },
        //     },
        // ],
    },
    {
        path: '/payments',
        name: 'Payments',
        component: () => import('@/views/Charge'),
        meta: {
            title: '결제페이지',
            wrap: 'sub',
        },
    },
    {
        path: '/payments/complete',
        name: 'payment callback url',
        component: () => import('@/views/PaymentComplete'),
        meta: {
            wrap: 'sub',
            header: 'no',
            footer: 'no'
        }
    },
    {
        path: '/policy',
        name: 'Policy',
        component: () => import('@/views/Policy'),
        meta: {
            wrap: 'sub',
        },
        children: [
            {
                path: 'terms-of-service',
                name: 'TermsOfService',
                component: () => import('@/components/policy/TermsOfService'),
                meta: {
                    title: '이용약관',
                },
            },
            {
                path: 'privacy-policy',
                name: 'PrivacyPolicy',
                component: () => import('@/components/policy/PrivacyPolicy'),
                meta: {
                    title: '개인정보처리방침',
                },
            },
            {
                path: 'youth-policy',
                name: 'YouthPolicy',
                component: () => import('@/components/policy/YouthPolicy'),
                meta: {
                    title: '청소년 보호정책',
                },
            },
        ],
    },
    {
        path: '/certification/complete',
        name: 'certification',
        component: () => import('@/views/CertificationComplete'),
        meta: {
            title: '본인 인증',
            wrap: 'sub',
            header: 'no',
            footer: 'no'
        } 
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    // router-link 또는 router.push 등을 이용해 페이지가 변환 될 경우 스크롤을 맨 위로 고정
    // 하지만 앞, 뒤로가기 버튼을 통해 움직일 경우 그 시점에서의 스크롤 값을 저장한다!
    // 해당 기능은 HTML5 히스토리 모드가 활성화 되어있어야 함.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

// 페이지 이동 시 마다
const makeTitle = (title) => (title ? ` ${title} | 오디오북` : '오디오북');

router.afterEach((to) => {
    document.title = makeTitle(to.meta.title);
});

export default router;
