<template>
	<div class="play_progress_wrap">
		<div class="play_progress">
			<div class="playbar_bg"></div>
			<div
				class="playbar_now"
				:style="{
					width: `${
						timeBar != 0
							? timeBar / (episodeDuration * 0.01) > 99
								? 99
								: timeBar / (episodeDuration * 0.01)
							: 0
					}%`,
				}"
			></div>
			<div
				class="play_point"
				:style="{
					left: `${
						timeBar != 0
							? timeBar / (episodeDuration * 0.01) > 99
								? 99
								: timeBar / (episodeDuration * 0.01)
							: 0
					}%`,
				}"
			></div>
			<input
				@input="inputTimeBar"
				@change="changeTimeBar"
				type="range"
				v-model="timeBar"
				min="0"
				:max="episodeDuration"
				step="1"
			/>
		</div>
	</div>
	<div class="play_control_wrap">
		<div class="play_control">
			<ul>
				<li @click="setPrevAudio"><i class="bx bx-skip-previous"></i></li>
				<li @click="beforeTen">-10</li>
				<li @click="playerStore.$patch({ playState: true })" v-if="!playState"><i class="bx bx-play"></i></li>
				<li @click="playerStore.$patch({ playState: false })" v-else><i class="bx bx-pause"></i></li>
				<li @click="stopPlayer"><i class="bx bx-stop"></i></li>
				<li @click="afterTen">+10</li>
				<li @click="setNextAudio"><i class="bx bx-skip-next"></i></li>
			</ul>
		</div>
		<div class="play_time">
			<span>{{ convertTimeFormat.HMS(timeBar) }}</span> /
			{{ convertTimeFormat.HMS(episodeDuration) }}
		</div>
		<div class="volumebar_wrap">
			<div class="volume_icon"><i class="bx bx-volume-full"></i></div>
			<div class="volume_progress">
				<div class="volume_bg"></div>
				<div class="volume_now" :style="{ width: `${volume}%` }"></div>
				<input type="range" v-model="volume" min="0" max="100" step="1" />
			</div>
		</div>
		<div class="m_play_time_wrap">
			<div class="m_play_time">
				<span>{{ convertTimeFormat.HMS(timeBar) }}</span> /
				{{ convertTimeFormat.HMS(episodeDuration) }}
			</div>
			<div class="m_volumebar_wrap">
				<div class="volume_icon"><i class="bx bx-volume-full"></i></div>
				<div class="volume_progress">
					<div class="volume_bg"></div>
					<div class="volume_now" :style="{ width: `${volume}%` }"></div>
					<input type="range" v-model="volume" min="0" max="100" step="1" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { contentPlayerStore } from '@/store/contentDetailStore';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { convertTimeFormat } from '@/common-methods/computedValue';

// player store setting
const playerStore = contentPlayerStore();

// player component info
const { playState, speed, episodeDuration, volume, publicTime, epiNum, timeBar, savePrevEpiNum } =
	storeToRefs(playerStore);

// 볼륨 변경 감지 시
watch(volume, (newData) => {
	playerStore.setVolume(newData);
});

// 10초 뒤 이동
const afterTen = () => {
	if (episodeDuration.value - timeBar.value < 10) {
		return false;
	} else {
		playerStore.setPublicTime(timeBar.value + 10);
	}
};
// 10초 전 이동
const beforeTen = () => {
	if (timeBar.value < 10) {
		playerStore.setPublicTime(0);
	} else {
		playerStore.setPublicTime(timeBar.value - 10);
	}
};

// 이전, 다음 음원 재생
const setPrevAudio = () => {
	// 이전
	if (savePrevEpiNum.value != null) {
		console.log(savePrevEpiNum.value - 1);
		playerStore.setPlayEpisodeInfo(savePrevEpiNum.value - 1, true);
	}
};
const setNextAudio = () => {
	// 다음
	if (savePrevEpiNum.value != null) {
		console.log(savePrevEpiNum.value + 1);
		playerStore.setPlayEpisodeInfo(savePrevEpiNum.value + 1, true);
	}
};

// 타임 바 Range 이동 시
const inputTimeBar = () => {
	playerStore.setPublicTime(timeBar.value);
	playerStore.setPlayState(false);
};

// 타임 바 시간 변경 완료 시
const changeTimeBar = () => {
	playerStore.setPublicTime(timeBar.value);
	playerStore.setPlayState(true);
};

// 플레이어 스탑 (타임바 초기화)
const stopPlayer = () => {
	playerStore.setPlayState(false);
	setTimeout(() => {
		playerStore.setPublicTime('reset');
		playerStore.setTimeBar(0);
	}, 150);
};
</script>

<style scoped>
input {
	width: 102%;
	cursor: pointer;
	position: absolute;
	top: -5px;
	opacity: 0;
}
</style>
