import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { convertGenreCode } from './common-methods/computedValue';
import piniaPersist from 'pinia-plugin-persist';
const image_host = process.env.VUE_APP_API_BASE_URL;

const app = createApp(App);
app.config.globalProperties.$image_host = image_host;
app.config.globalProperties.$convertGenreCode = convertGenreCode;
app.use(router).use(createPinia().use(piniaPersist)).mount('#app');
// window.Kakao.init('0275f03d0979a1cee0ee853872205843'); // 김용성 사원 개발자 계정 SDK 키 값
window.Kakao.init('40cefff8ceb12806fbfbbeec28a0e1f4'); // 정태현 사원 개발자 계정 SDK 키 값으로 변경
