<template>
	<audio
		@error="audioErorr"
		@ended="audioEnded"
		@timeupdate="onTimeUpdateListener"
		id="realAudio"
		ref="player"
		preload="auto"
	>
		<!-- <source id="audioSource" src="" /> -->
		해당 브라우저에서 지원되지 않는 기능입니다. 구글 크롬 또는 네이버 웨일 브라우저를 사용해주세요.
	</audio>
</template>

<script setup>
import { contentPlayerStore } from '@/store/contentDetailStore';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

const player = ref(); // audio tag ref

// player store setting
const playerStore = contentPlayerStore();

// player component info
const {
	playList,
	publicTime,
	audioPath,
	playState,
	speed,
	volume,
	epiNum,
	allEpisodes,
	loop,
	timeBar,
	savePrevEpiNum,
	illustIdx,
} = storeToRefs(playerStore);

// 오디오 태그 에러 발생 시
// 멤버쉽 체크 당시엔 파일의 경로를 확인해서 존재여부 확인
// 이곳에선 경로의 파일이 오디오 태그로 재생이 가능한지 확인
const audioErorr = (error) => {
	console.log(error);
	if (audioPath.value != '') {
		alert('음원파일에 문제가 있어 재생할 수 없습니다.');
		// 파일 경로는 올바르지만 파일 자체 문제일 경우 플레이어 정보 초기화
		playerStore.resetEpisodeInfo();
		playerStore.resetPlayInfo();
	}
};

// 실제 플레이 리스트 변경 시
watch(playList, (newData) => {
	player.value.pause();
	player.value.src = '';
	player.value.load();
	playerStore.resetPlayInfo();
	// 재생 시간 및 삽화 리스트 인덱스 초기화
	publicTime.value = 0;
	illustIdx.value = 0;
});

// 플레이어 시간 업데이트 시 (4hz)
const onTimeUpdateListener = () => {
	console.log(player.value.currentTime);
	playerStore.setTimeBar(player.value.currentTime);
};

// 공통 재생 시간 변수 변경 감지
watch(publicTime, (newData) => {
	if (newData === 'reset') {
		player.value.currentTime = 0;
		playerStore.setPublicTime(0);
		return false;
	}
	player.value.currentTime = newData;
});

// 재생 속도 변경 시
watch(speed, (newData) => {
	console.log(`speed : ${newData}`);
	player.value.playbackRate = newData;
});

// 재생 여부  변경 시
watch(playState, (state) => {
	if (state) {
		if (epiNum.value === 0 && audioPath.value === '') {
			playerStore.setPlayEpisodeInfo(1);
			return false;
		}
		setTimeout(() => {
			player.value.play();
		}, 100);
	} else {
		player.value.pause();
	}
});

// 오디오 소스 경로 변경 시
watch(audioPath, (newData) => {
	if (newData != '') setPlayerSrc(`https://www.toonflex.co.kr/sbapi/contents/${newData}`);
});

// 볼륨 변경 감지 시
watch(volume, (newData) => {
	console.log(`volume : ${newData}`);
	player.value.volume = newData * 0.01;
});

// 플레이어 영상 소스 세팅
function setPlayerSrc(url) {
	player.value.src = url;
	console.log(player.value.src);
	player.value.load();
	player.value.playbackRate = speed.value;
	player.value.play();
	playerStore.setPlayState(true);
}

const audioEnded = () => {
	console.log('재생 완료');
	playerStore.setPlayState(false);
	// TODO. 다음 음원 재생 로직
	if (playList.value.length === 1 && loop.value) {
		// 에피소드가 한개만 있고 전체반복 활성화 시 store 멤버쉽 체크를 하지 않고 해당 음원 다시 재생
		setTimeout(() => {
			player.value.load();
			player.value.playbackRate = speed.value;
			playerStore.setPlayState(true);
		}, 1000);
		return false;
	}
	setTimeout(() => {
		playerStore.setPlayEpisodeInfo(epiNum.value + 1);
	}, 1000);
};
</script>

<style scoped>
#realAudio {
	display: none;
}
</style>
