<template>
	<div class="player_bg_wrap_down">
		<div class="player_wrap_down">
			<div class="player_body_wrap_down">
				<div class="head_icon_wrap_down">
					<div class="head_circle_down">
						<a @click="playerPosition = 'top'" href="javascript:void(0);"
							><i class="bx bx-up-arrow-alt"></i
						></a>
					</div>
					<div class="head_circle_down">
						<a @click="closePlayer" href="javascript:void(0);"><i class="bx bx-x"></i></a>
					</div>
				</div>
				<div class="player_header_down" v-if="contentInfo">
					<div class="player_thumb_down">
						<img alt="content thumbnail" :src="`${$image_host}/contents/${contentThumbnail}`" />
						<!-- <img alt="content thumbnail" :src="`${$image_host}/contents/${contentInfo.thumb_path}`" /> -->
					</div>
					<div class="playing_tit_wrap">
						<h3>
							<span>{{ epiNum ? `[ep.${epiNum}]` : '회차를 선택해 주세요' }}</span
							>{{ playEpisodeInfo.epi_title }}
						</h3>
						<div class="sub_tit_wrap">
							<!-- <p>{{ contentInfo.opus_title }}</p> -->
							<p>{{ contentTitle }}</p>
						</div>
					</div>
					<div class="set_speed_wrap_down">
						<button type="button" class="set_speed" @click="visibleSpeedSelector = true">
							{{ speed }}x
						</button>
						<div class="speed_select_box_down" v-show="visibleSpeedSelector">
							<div class="speed_select_wrap">
								<ul>
									<li
										@click="speed = item"
										:class="{ active: item === speed }"
										v-for="(item, index) in speedArr"
										:key="index"
									>
										{{ item }}x
									</li>
									<li @click="visibleSpeedSelector = false" class="close">닫기</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="play_progress_wrap_down">
					<div class="play_progress">
						<div class="playbar_bg"></div>
						<div
							class="playbar_now"
							:style="{ width: `${timeBar != 0 ? timeBar / (episodeDuration * 0.01) : 0}%` }"
						></div>
						<div
							class="play_point"
							:style="{ left: `${timeBar != 0 ? timeBar / (episodeDuration * 0.01) : 0}%` }"
						></div>
						<input
							@input="inputTimeBar"
							@change="changeTimeBar"
							type="range"
							v-model="timeBar"
							min="0"
							:max="episodeDuration"
							step="1"
						/>
					</div>
				</div>
				<div class="play_control_wrap_down">
					<div class="play_control">
						<ul>
							<li @click="setPrevAudio"><i class="bx bx-skip-previous"></i></li>
							<li @click="beforeTen">-10</li>
							<li @click="playerStore.$patch({ playState: true })" v-if="!playState">
								<i class="bx bx-play"></i>
							</li>
							<li @click="playerStore.$patch({ playState: false })" v-else>
								<i class="bx bx-pause"></i>
							</li>
							<li @click="stopPlayer">
								<i class="bx bx-stop"></i>
							</li>
							<li @click="afterTen">+10</li>
							<li @click="setNextAudio"><i class="bx bx-skip-next"></i></li>
						</ul>
					</div>
					<div class="play_time">
						<span>{{ convertTimeFormat.HMS(timeBar) }}</span> / {{ convertTimeFormat.HMS(episodeDuration) }}
					</div>
					<div class="volumebar_wrap">
						<div class="volume_icon"><i class="bx bx-volume-full"></i></div>
						<div class="volume_progress">
							<div class="volume_bg"></div>
							<div class="volume_now" :style="{ width: `${volume}%` }"></div>
							<input type="range" v-model="volume" min="0" max="100" step="1" />
						</div>
					</div>
					<div class="m_play_time_wrap">
						<div class="m_play_time">
							<span>{{ convertTimeFormat.HMS(timeBar) }}</span> /
							{{ convertTimeFormat.HMS(episodeDuration) }}
						</div>
						<div class="m_volumebar_wrap">
							<div class="volume_icon"><i class="bx bx-volume-full"></i></div>
							<div class="volume_progress">
								<div class="volume_bg"></div>
								<div class="volume_now" :style="{ width: `${volume}%` }"></div>
								<input type="range" v-model="volume" min="0" max="100" step="1" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { contentDetailInfoStore, contentPlayerStore } from '@/store/contentDetailStore';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { convertTimeFormat } from '@/common-methods/computedValue';

// 재생 속도 리스트
const speedArr = ref([0.5, 1, 1.2, 1.5, 2]);
const visibleSpeedSelector = ref(false);

// store setting
const playerStore = contentPlayerStore();
const detailStore = contentDetailInfoStore();

// player component info
const {
	playerVisible,
	playerPosition,
	playState,
	speed,
	playEpisodeInfo,
	episodeDuration,
	volume,
	publicTime,
	epiNum,
	timeBar,
	contentThumbnail,
	contentTitle,
} = storeToRefs(playerStore);

// content info
const { contentInfo } = storeToRefs(detailStore);

// 볼륨 변경 감지 시
watch(volume, (newData) => {
	playerStore.setVolume(newData);
});

// 10초 뒤 이동
const afterTen = () => {
	if (episodeDuration.value - timeBar.value < 10) {
		return false;
	} else {
		playerStore.setPublicTime(timeBar.value + 10);
	}
};
// 10초 전 이동
const beforeTen = () => {
	if (timeBar.value < 10) {
		playerStore.setPublicTime(0);
	} else {
		playerStore.setPublicTime(timeBar.value - 10);
	}
};

// 이전, 다음 음원 재생
const setPrevAudio = () => {
	// 이전
	playerStore.setPlayEpisodeInfo(epiNum.value - 1, true);
};
const setNextAudio = () => {
	// 다음
	playerStore.setPlayEpisodeInfo(epiNum.value + 1, true);
};

// 타임 바 Range 이동 시
const inputTimeBar = () => {
	playerStore.setPublicTime(timeBar.value);
	playerStore.setPlayState(false);
};

// 타임 바 시간 변경 완료 시
const changeTimeBar = () => {
	playerStore.setPublicTime(timeBar.value);
	playerStore.setPlayState(true);
};

// 플레이어 종료
const closePlayer = () => {
	if (confirm('플레이어를 종료하시겠습니까?')) {
		playerVisible.value = false;
		playerStore.resetPlayInfo();
	}
};

// 플레이어 스탑 (타임바 초기화)
const stopPlayer = () => {
	playerStore.setPlayState(false);
	setTimeout(() => {
		playerStore.setPublicTime('reset');
		playerStore.setTimeBar(0);
	}, 150);
};
</script>

<style scoped>
input {
	width: 102%;
	cursor: pointer;
	position: absolute;
	top: -5px;
	opacity: 0;
}
</style>
