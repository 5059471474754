import axiosService from '../index';

// 메인 홈페이지에서 보여지는 장르별, 신작, 랭킹 리스트 출력을 위한 API 모듈

// 메인 배너 리스트
const banner = (params) => {
	return axiosService.get(`/banner/main/audio/list`, {
		params,
	});
};

// 장르별 작품 리스트
const byGenre = (params) => {
	return axiosService.get(`/audio/home/genre`, {
		params,
	});
};

// 신작 소개 배너 리스트
const byNewBanner = (params) => {
	return axiosService.get('/banner/new/audio/list', {
		params,
	});
};

// 실시간 랭킹 리스트
const byRanking = (params) => {
	return axiosService.get('/audio/home/time', {
		params,
	});
};

// 이벤트 배너 리스트
const byEventBanner = (params) => {
	return axiosService.get('/event/main/home', {
		params,
	});
};

//검색리스트
const bySearch = (parameters) => {
	return axiosService.get(`/opus/search/${parameters.page}`, {
		params: parameters,
	});
};

export default {
	byGenre,
	byNewBanner,
	banner,
	byRanking,
	byEventBanner,
	bySearch,
};
