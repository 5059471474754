<template>
	<div class="footer_wrap">
		<div class="footer_top_wrap">
			<div class="footer_top">
				<ul>
					<!-- <li><a href="#">회사소개</a></li> -->
					<li><router-link to="/policy/terms-of-service">이용약관</router-link></li>
					<li><router-link to="/policy/privacy-policy">개인정보처리방침</router-link></li>
					<li><router-link to="/policy/youth-policy">청소년 보호정책</router-link></li>
					<!-- <li><a href="#">업무제휴문의</a></li> -->
				</ul>
			</div>
		</div>
		<div class="footer_company_info">
			<p>
				스토리버드에 게시된 모든 콘텐츠들은 저작권법에 의거 보호받고 있습니다. <br />
				저작권자 또는 (주)아이디어 콘서트의 승인 없이 콘텐츠의 일부 또는 전체를 복사, 전송, 배포를 포함하는
				일체의 행위는 저작권법에 의해 법적 조치에 처해질 수 있으므로 주의하시길 바랍니다.
			</p>
			<p>
				(주)아이디어 콘서트사업자등록번호 : 338-88-00131통신판매업신고번호 : 제2017-성남분당-0029호대표 : 전달용
				<br />
				주소 : 판교 테크노벨리고객센터 : <a href="tel:070-8825-5004">070-8825-5004</a>
			</p>
			<p>ⓒ IDEACONCERT Co., Ltd. All Right Reserved.</p>
		</div>
	</div>
</template>

<script setup></script>

<style></style>
