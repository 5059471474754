import axiosService from '../index';

// 서브 페이지들(장르별, 신작, 랭킹, 이벤트 등..)에서 리스트 출력을 위한 API 모듈

// 장르 페이지
const byGenre = (parameters) => {
	return axiosService.get(`/audio/genre/${parameters.page}`, {
		params: parameters,
	});
};

// 신작 페이지
const byNew = (parameters) => {
	return axiosService.get(`/audio/new/${parameters.page}`, {
		params: parameters,
	});
};

// 랭킹 페이지
const byRanking = (parameters) => {
	return axiosService.get(`/audio/ranking/${parameters.page}`, {
		params: parameters,
	});
};

// 랭킹 페이지
const byEvent = (parameters) => {
	return axiosService.get(`/audio/event/${parameters.page}`, {
		params: parameters,
	});
};

export default {
	byGenre,
	byNew,
	byRanking,
	byEvent,
};
