<template>
	<transition name="fade" mode="out-in">
		<div class="header_warp" v-show="headerVisibleState">
			<div class="header_list_warp">
				<div class="header_logo">
					<router-link to="/"><img src="../assets/new_logo.png" /></router-link>
					<!-- <router-link to="/"><img src="../assets/logo.png" /></router-link> -->
				</div>
				<ul class="header_ul">
					<li><router-link to="/sub/genre">장르별</router-link></li>
					<li><router-link to="/sub/new">신작</router-link></li>
					<li><router-link to="/sub/ranking">랭킹</router-link></li>
					<li><router-link to="/sub/event">이벤트</router-link></li>
					<li><router-link to="/sub/servicecenter">고객센터</router-link></li>
				</ul>
				<div class="header_icon_wrap">
					<button type="button">
						<i @click="search_store.search_modal = true" class="bx bx-search"></i>
					</button>
					<button type="button" @click="visibleSideMenu = true">
						<i class="bx bx-menu close_x"></i>
					</button>
				</div>
			</div>
		</div>
	</transition>

	<div class="all_menu_wrap" v-show="visibleSideMenu">
		<div class="menu_section">
			<div class="menu">
				<div class="menu_head">
					<div v-if="token_valid === true" class="member_name">
						<span>{{ nickname }}</span
						>님
					</div>
					<div v-if="token_valid === false" class="member_name"><span>방문고객</span>님</div>
					<div v-if="token_valid === true" class="member_category">
						고객님은 <span style="color: lightgreen" v-if="store.membership == true">멤버쉽 </span
						><span style="color: red" v-else>비 멤버쉽</span>회원 입니다.
					</div>
					<div v-if="token_valid === false" class="member_category">
						서비스 이용을 위해 <span>로그인</span>을 해주세요.
					</div>
					<div v-if="token_valid === true" class="menu_head_btm">
						<button @click="deleteAllCookies()" v-if="token_valid === true" type="button">로그아웃</button>
						<button @click="router.push('/mypage/account')" v-if="token_valid === true" type="button">
							정보수정
						</button>
					</div>
					<div v-if="token_valid === false" class="menu_head_btm">
						<button
							@click="router.push('/login')"
							v-if="token_valid === false"
							type="button"
							style="margin-left: 0"
						>
							로그인
						</button>
					</div>
					<div class="menu_close">
						<button type="button" @click="visibleSideMenu = false"><i class="bx bx-x"></i></button>
					</div>
				</div>
				<div class="menu_line"></div>
				<div class="main_content_menu">
					<ul>
						<li>
							<router-link to="/sub/genre">장르별<i class="bx bxs-chevron-right"></i></router-link>
						</li>
						<li>
							<router-link to="/sub/new">신작<i class="bx bxs-chevron-right"></i></router-link>
						</li>
						<li>
							<router-link to="/sub/ranking">랭킹<i class="bx bxs-chevron-right"></i></router-link>
						</li>
						<li>
							<router-link to="/sub/event">이벤트<i class="bx bxs-chevron-right"></i></router-link>
						</li>
						<li>
							<router-link to="/sub/servicecenter"
								>고객센터<i class="bx bxs-chevron-right"></i
							></router-link>
						</li>
					</ul>
				</div>
				<div class="menu_line"></div>
				<div class="mypage_menu_wrap">
					<ul>
						<li><router-link to="/mypage/like">좋아요 리스트</router-link></li>
						<!-- <li><router-link to="/mypage/content">콘텐츠 보관함</router-link></li> -->
						<li><router-link to="/mypage/membership">나의 요금제 / 회원권 결제</router-link></li>
						<li><router-link to="/mypage/playlist">나의 재생 리스트</router-link></li>
						<li><router-link to="/mypage/gift">쿠폰함</router-link></li>
						<!-- <li>
							<a href="03.index _메뉴오픈.html">성우 페이지<i class="bx bxs-chevron-down"></i></a>
						</li>
						<li>
							<a href="03.index _메뉴오픈.html">작가 페이지<i class="bx bxs-chevron-down"></i></a>
						</li> -->
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div v-if="search_store.search_modal" class="modal_wrap">
		<div class="modal modal_500">
			<div class="modal_head mgb20">
				<h2>작품검색</h2>
				<a @click="search_store.search_modal = false"><i class="bx bx-x"></i></a>
			</div>

			<div class="input_btn_wrap">
				<div class="item_id">
					<input v-model="search_input" type="text" id="id" placeholder="제목 및 작가명으로 검색해주세요!" />
				</div>
				<div class="btn">
					<button @click="search_complete()" type="button" class="btn_green_bg">검색</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { userInfoStore } from '@/store/UserStore';
import { SearchStore } from '@/store/Search';
import { SearchTextStore } from '@/store/Search';
import { useRouter } from 'vue-router';
import axios from 'axios';
import jwt from '@/api/LocalStorage';

// 메뉴 출력 여부
const naver_token = jwt.getToken('naver');
const visibleSideMenu = ref(false);
const ID_TOKEN_KEY = 'id_token';
const route = useRoute();
const search_text_store = SearchTextStore();
const search_store = SearchStore();
const store = userInfoStore();
const router = useRouter();
const nickname = ref('');
const token_valid = ref(false);
const search_input = ref('');
// const membership = ref(jwt.getToken('membership'));

// Status to determine header output
const headerVisibleState = ref(true);
var isScrolling = null;
var currentScrollY = 0;

const onScroll = () => {
	if (currentScrollY < window.scrollY) {
		window.clearTimeout(isScrolling); // 스크롤 이후 .5초후 실행되는 로직을 스크롤 마다 생성한 뒤 이전 스크롤에서 생겼던 callback을 없애면서
		// .7초가 지나기전 다시 스크롤 되면 예약되었던 로직을 실행을 안하기 때문에 조금씩 스크롤을 내릴 때 헤더가 깜빡거리지 않게 된다
		headerVisibleState.value = false;
		// Set a timeout to run after scrolling ends
		isScrolling = setTimeout(() => {
			// Run the callback
			headerVisibleState.value = true;
			console.log('Scrolling has stopped.');
		}, 700);
		currentScrollY = window.scrollY;
	} else {
		headerVisibleState.value = true;
		currentScrollY = window.scrollY;
	}
};

onMounted(() => {
	window.addEventListener('scroll', onScroll);
});

const search_complete = () => {
	search_text_store.search_text = search_input.value;
	search_store.search_modal = false;
	window.location.replace('/search');
};

watch(headerVisibleState, (state) => {
	console.log(state);
});
const deleteAllCookies = () => {
	const del_local = () => {
		// router.go();
		alert('로그아웃이 완료되었습니다.');
		window.location.href = '/';
		jwt.destroyToken(ID_TOKEN_KEY);
		jwt.destroyToken('id');
		jwt.destroyToken('nickname');
		jwt.destroyToken('userNo');
		jwt.destroyToken('loginType');
		jwt.destroyToken('membership');
	};
	//카카오토큰 유무 확인
	if (window.Kakao.Auth.getAccessToken()) {
		//인증토큰을 로컬에서 삭제하는 메소드
		//이것만 사용시 연결이 끊기지 않고 바로 로그인과정 스킵해서 다시로그인됨
		window.Kakao.Auth.logout(function (data) {
			window.Kakao.Auth.getAccessToken(del_local());
		});
	} else if (jwt.getToken('naver')) {
		//네이버는 로그아웃처리 메소드가 따로없음
		//그래서 저 URL로 보내주면 쿠키에있는 네이버 로그인 데이터가 날아감
		//디스트로이드 토큰은 네이버 로그인시 임의로 만들어준 인증 로컬토큰
		// window.open('http://nid.naver.com/nidlogin.logout', '_blank');
		axios
			.get(
				`https://nid.naver.com/oauth2.0/token?grant_type=delete&client_id=TEA0Alw6wlCncJct7Oda&client_secret=pIT61g7nD7&access_token=${naver_token}&service_provider=NAVER`,
			)
			.then((res) => {
				console.log(res);
			});

		jwt.destroyToken('naver');
		del_local();
	} else {
		//일반로그인 로그아웃처리
		del_local();
	}
	token_valid.value = false;
};
// watch(nickname, (newData) => {
// 	nickname.value = store.user_info_list.nickname;asd
// });
// 사이드 메뉴에서 페이지 이동 시 사이드 메뉴창 닫힘
watch(
	() => route.path,
	() => {
		visibleSideMenu.value = false;
	},
);
watch(
	() => visibleSideMenu.value,
	() => {
		setTimeout(() => {
			if (store?.user_info_list !== null) {
				nickname.value = store.user_info_list.nickname;
			}
		}, 100);

		if (jwt.getToken(ID_TOKEN_KEY)) {
			store.user_info();
			token_valid.value = true;
		} else if (!jwt.getToken(ID_TOKEN_KEY)) {
			token_valid.value = false;
		}
	},
);
setTimeout(() => {
	if (jwt.getToken('id_token')) {
		store.user_info();
		store.user_membershipCheck();
	}
}, 100);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
