const getToken = (id) => {
	return window.localStorage.getItem(id);
};

const saveToken = (id, token) => {
	window.localStorage.setItem(id, token);
};

const destroyToken = (id) => {
	window.localStorage.removeItem(id);
};

export default {
	getToken,
	saveToken,
	destroyToken,
};
