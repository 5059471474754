<template>
	<div class="playlist_box_wrap" v-show="!imgTab">
		<div class="playlist_top_wrap">
			<h4>에피소드</h4>
			<div @click="playerStore.$patch({ loop: !loop })" :class="{ on: loop, off: !loop }" class="play_repeat">
				<i class="bx bx-repeat"></i>전체반복
			</div>
			<!-- <div class="play_select">선택</div> -->
		</div>
		<div class="playlist_wrap">
			<div
				class="playlist_one"
				:class="{ active: item.epi_num == playerStore.savePrevEpiNum }"
				v-for="(item, index) in playList"
				:key="index"
			>
				<div class="play_info">
					<div class="play_ep_tit">
						<span>[ep.{{ item.epi_num }}]</span>
						{{ item.epi_title }}
						<span class="free_audio" v-if="item.free_yn == 'Y'">무료</span>
					</div>
					<div class="play_date_time">
						{{ convertTimeFormat.YMD(item.reg_date) }}
						<span>[{{ convertTimeFormat.HMS(item.file_duration) }}]</span>
					</div>
				</div>
				<div class="playbtn_wrap">
					<!-- <button
							v-if="item.buy_state != 3"
							type="button"
							class="circle_basic_btn tooltip"
							:class="{ circle_grey: item.buy_state == 1, circle_deep_green: item.buy_state != 1 }"
						>
							<div v-if="item.buy_state == 1" class="tooltop_box tooltop_box_rental">
								이미 대여한 콘첸트 입니다.
							</div>
							대여
						</button>
						<button
							v-if="item.buy_state != 1"
							type="button"
							class="circle_basic_btn tooltip"
							:class="{ circle_grey: item.buy_state == 3, circle_deep_green: item.buy_state != 3 }"
						>
							<div v-if="item.buy_state == 3" class="tooltop_box tooltop_box_buy">
								이미 구매한 콘텐츠 입니다.
							</div>
							구매
						</button> -->
					<!-- <button
							v-if="item.buy_state == 1 || item.buy_state == 3"
							type="button"
							class="circle_basic_btn circle_green_fill"
						>
							<i class="bx bx-play"></i>
						</button>
						<button v-else type="button" class="circle_basic_btn circle_grey_fill tooltip">
							<i class="bx bx-play"></i>
							<div class="tooltop_box tooltop_box_play">대여 또는 구매 후 감상이 가능합니다.</div>
						</button> -->
					<button
						@click="setCurentEpisode(item.epi_num)"
						type="button"
						class="circle_basic_btn circle_green_fill"
					>
						<i class="bx bx-play"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div
		id="contentBG"
		v-if="contentInfo"
		class="story_img_wrap_show"
		v-show="imgTab"
		:style="{
			'--bgImage': `url(${$image_host}/contents/${contentThumbnail})`,
		}"
	>
		<div class="blackBox"></div>
		<div class="blurBox"></div>
		<div class="story_img_area">
			<div
				@click="
					playerStore.$patch({
						illustIdx: illustIdx == 0 ? illustArr.length - 1 : (illustIdx -= 1),
					})
				"
				class="arrow_left"
			>
				<button type="button" class="">
					<i class="bx bx-arrow-back" style="color: #ffffff; position: absolute"></i>
				</button>
			</div>
			<!-- 삽화가 있는 에피소드일 경우 -->
			<div class="story_img" v-if="illustArr.length > 0">
				<img alt="illust" :src="`${$image_host}/contents/${illustArr[illustIdx].file_path}`" />
				<div class="order">{{ illustIdx + 1 }}/{{ illustArr.length }}</div>
			</div>

			<!-- 삽화가 없는 에피소드일 경우 -->
			<div class="story_img" v-if="illustArr.length == 0">
				<span class="noIllust" v-if="playEpisodeInfo.epi_num"> 삽화가 없는 회차입니다!</span>
				<span class="noIllust" v-else> 회차를 선택해 주세요!</span>
			</div>
			<div @click="changeIllust" class="arrow_right">
				<button
					@click="
						playerStore.$patch({
							illustIdx: illustIdx == illustArr.length - 1 ? 0 : (illustIdx += 1),
						})
					"
					type="button"
					class=""
				>
					<i class="bx bx-arrow-back bx-flip-horizontal" style="color: #ffffff"></i>
				</button>
			</div>
		</div>
	</div>

	<!-- <div class="price_info_wrap">
		<dl>
			<dt>이용 요금 안내</dt>
			<dd>
				-
				<ul>
					<li>
						전체대여 <span>{{ playerStore.getTotalRentPrice }}</span
						>/
					</li>
					<li>
						전체구매 <span>{{ playerStore.getTotalProductPrice }}</span>
					</li>
				</ul>
			</dd>
			<dd>- 월 정액 이용 회원은 <span>무제한</span>으로 이용 가능하십니다.</dd>
		</dl>
	</div> -->
</template>

<script setup>
import { contentPlayerStore, contentDetailInfoStore } from '@/store/contentDetailStore';
import { storeToRefs } from 'pinia';
import { convertTimeFormat } from '@/common-methods/computedValue';
import { onMounted, ref, watch } from 'vue';

// 플레이어 리스트 세팅
const playerStore = contentPlayerStore();
// 작품
const detailStore = contentDetailInfoStore();

const { contentInfo } = storeToRefs(detailStore);
const { allEpisodes, imgTab, loop, playEpisodeInfo, illustArr, illustIdx, playList, contentThumbnail } =
	storeToRefs(playerStore);

const setCurentEpisode = (num) => {
	playerStore.setPlayEpisodeInfo(num);
};

// 삽화 구역 백그라운드 이미지
// const bgImage = ref(`http://183.111.234.51:9002/contents/${contentInfo.thumb_path}`);
// onMounted(() => {
// 	backdrop.value.backgroundImage = `url(${contentInfo.value.thumb_path})`;
// });
// watch(contentInfo, () => {
// 	console.log(contentInfo.value);
// 	backdrop.value.style.backgroundImage = `url(${contentInfo.value.thumb_path})`;
// });

// 삽화 이미지 리스트
watch(illustArr, (arr) => {
	console.log(arr);
});
</script>

<style>
#contentBG::after {
	background: var(--bgImage);
	background-repeat: no-repeat;
	background-size: cover;
}

.blackBox {
	opacity: 0.7;
	background: #161616;
	left: 0;
	top: 0;
	width: 100%;
	height: 350px;
	position: absolute;
}
.blurBox {
	backdrop-filter: blur(3px);
	left: 0;
	top: 0;
	width: 100%;
	height: 350px;
	position: absolute;
}
.noIllust {
	color: white;
}
</style>
