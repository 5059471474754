import { defineStore } from 'pinia';
import ajax from '@/api/api-manager';
import moment from 'moment';
import log_response from '@/common-methods/AxiosResponseLog';
import axios from 'axios';
import { userInfoStore } from './UserStore';
import jwt from '@/api/LocalStorage';

// 작품 상세보기 페이지 전용 store들

// 작품 상세정보 store
export const contentDetailInfoStore = defineStore('contentDetail', {
	state: () => ({
		contentInfo: null, // 작품 정보
		favoriteInfo: null, // 작품 좋아요 여부
		opus_no: null, // 작품 번호
		contentVisibleState: false, // 작품 정보 출력 여부
	}),
	getters: {},
	actions: {
		// 작품 상세 정보
		getContentDetail(idx) {
			this.opus_no = idx;
			this.contentInfo = null;
			this.contentVisibleState = false;
			const params = {
				idx,
			};
			ajax('content/contentDetail', params)
				.then((response) => {
					if (response.data.data.del_yn === 'Y') {
						alert('삭제처리 된 작품입니다.');
						window.location.href = '/';
						return false;
					}

					// jwt 토큰 값 체크해서 로그인 상태인지 확인
					// router.push 등의 값으로 컴포넌트만 바뀐게 아니라 다이렉트로 주소를 바꿔서 새로고침이 되버릴 경우
					// userStore값이 초기화 되기 때문에 로그인 여부를 알 수가 없어 jwt 토큰을 통해 다시 확인
					if (response.data.data.age_limit == 19) {
						if (jwt.getToken('id')) {
							// id 토큰이 존재할 경우 userStore 조회 대신 user_info API를 다시 조회하여 유저 정보값을 가져온다
							// 해당 로직은 성인작품 페이지 조회 시에만 사용된다
							ajax('user/user_info').then((userRes) => {
								// if (response.data.data.age_limit == 19) {
								if (!userRes.data.data.birth_date) {
									alert('본인인증을 먼저 진행해주세요');
									window.location.href = '/';
									return false;
								} else {
									const birth_date = moment(userRes.data.data.birth_date);
									if (moment().diff(birth_date.format('YYYYMMDD'), 'years') < 19) {
										alert('연령 제한이 되어있는 작품입니다.');
										window.location.href = '/';
										return false;
									} else {
										// 성인작품이 아니거나 현재 로그인한 사용자가 본인인증 (성인)을 마쳤을 경우
										// 정상적으로 작품 정보를 노출시킨다.
										this.contentInfo = response.data.data;
										this.contentVisibleState = true;
									}
								}
								// } else {
								// 성인작품이 아니거나 현재 로그인한 사용자가 본인인증 (성인)을 마쳤을 경우
								// 정상적으로 작품 정보를 노출시킨다.
								// this.contentInfo = response.data.data;
								// this.contentVisibleState = true;
								// }
							});
						} else {
							alert('성인용 작품은 로그인이 필요합니다');
							window.location.href = '/';
							return false;
						}
					} else {
						// 성인작품이 아니거나 현재 로그인한 사용자가 본인인증 (성인)을 마쳤을 경우
						// 정상적으로 작품 정보를 노출시킨다.
						this.contentInfo = response.data.data;
						this.contentVisibleState = true;
					}

					log_response('getContentDetail', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// 작품 좋아요 여부
		checkAlreadyContentFavorite(idx) {
			const params = {
				idx,
			};
			ajax('content/checkFavorite', params)
				.then((response) => {
					this.favoriteInfo = response.data.data;
					log_response('checkAlreadyContentFavorite', response.data);
				})
				.catch((error) => {
					console.log(error.response.data);
				});
		},

		// 작품 좋아요 토글
		toggleFavorite(type) {
			const params = {
				opus_no: this.opus_no,
			};
			console.log(type);
			if (type !== 'Y') {
				// 좋아요를 이미 한 경우
				ajax('content/registFavorite', params)
					.then((response) => {
						this.contentInfo.fav_cnt += 1;
						this.favoriteInfo = 'Y';
						log_response('toggleFavorite', response.data);
					})
					.catch((error) => {
						console.log(error.response.data);
					});
			} else {
				// 아직 안한 경우
				ajax('content/cancelFavorite', params)
					.then((response) => {
						this.contentInfo.fav_cnt -= 1;
						this.favoriteInfo = 'N';
						log_response('toggleFavorite', response.data);
					})
					.catch((error) => {
						console.log(error.response.data);
					});
			}
		},
	},
});

// 작품 회차 store
export const contentEpisodeStore = defineStore('contentEpisode', {
	state: () => ({
		allEpisodeList: [],
		episodeList: [],
		order: 'NEW',
		page: 1,
		totalPage: null,
		totalItemCount: null,
		idx: null,
	}),
	getters: {
		getEpisodesDuration() {
			let sumSecondDuration = 0;
			this.episodeList.forEach((episode) => {
				sumSecondDuration += episode.file_duration;
			});
			const duration = moment.duration(sumSecondDuration, 'seconds');
			return `${duration.hours()}시간 ${duration.minutes()}분 ${duration.seconds()}초`;
		},
	},
	actions: {
		// 작품 전체 에피소드 목록 가져오기
		getAllEpisodeList(idx) {
			this.idx = idx;
		},

		// 작품 에피소드 목록
		getEpisodeList(idx) {
			this.idx = idx;
			const params = {
				idx,
				page: this.page,
				order: this.order,
			};
			ajax('content/episodeList', params)
				.then((response) => {
					this.totalPage = response.data.totalpage;
					this.totalItemCount = response.data.totalrecordcount;
					this.episodeList = response.data.data;
					log_response('getEpisodeList', response.data);
				})
				.catch((error) => {
					console.log(error.response.data);
				});
		},

		// 에피소드 리스트 조회 정렬 방식
		setOrder(order) {
			this.order = order;
			this.getEpisodeList(this.idx);
		},
		// 페이지 변경 시 호출
		setPage(page) {
			this.page = page;
			this.getEpisodeList(this.idx);
		},
	},
});

// 작품 댓글 store
export const contentCommentStore = defineStore('contentComment', {
	state: () => ({
		commentList: [],
		page: 1,
		totalPage: null,
		totalItemCount: null,
		opus_no: null,
	}),
	getters: {
		getCommentCnt() {
			return this.commentList.length;
		},
	},
	actions: {
		// 작품 댓글 목록
		getContentComment(idx) {
			this.opus_no = idx;
			const params = {
				page: this.page,
				wt_idx: idx,
			};
			ajax('content/commentList', params)
				.then((response) => {
					this.commentList = response.data.data;
					this.totalPage = response.data.totalpage;
					this.totalItemCount = response.data.totalrecordcount;
					log_response('getContentComment', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// 작품 댓글 등록
		insertComment(comment) {
			const params = {
				opus_no: this.opus_no,
				reply_content: comment,
			};
			ajax('content/insertComment', params)
				.then((response) => {
					contentModalStore().setModalActive('true');
					contentModalStore().setModalType('insert');
					this.getContentComment(this.opus_no);
					log_response('insertComment', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		// 작품 댓글 삭제
		deleteComment(comment) {
			const params = {
				replyNo: comment.reply_no,
			};
			ajax('content/deleteComment', params).then((response) => {
				contentModalStore().setModalActive('true');
				contentModalStore().setModalType('delete');
				this.getContentComment(this.opus_no);
				log_response('deleteComment', response.data);
			});
		},

		// 페이지 변경 시 호출
		setPage(page) {
			this.page = page;
			this.getContentComment(this.opus_no);
		},
	},
});

// 작품 이벤트 store
export const contentEventStore = defineStore('contentEvent', {
	state: () => ({
		eventInfo: null,
	}),
	getters: {
		getEventDuration() {
			let startDate = moment(this.eventInfo.start_date).format('YYYY. MM. DD');
			let endDate = moment(this.eventInfo.end_date).format('YYYY. MM. DD');
			return `${startDate} ~ ${endDate}`;
		},
	},
	actions: {
		// 작품 댓글 목록
		getContentEvent(idx) {
			const params = {
				idx,
			};
			ajax('content/contentEvent', params)
				.then((response) => {
					this.eventInfo = response.data.data[0];
					log_response('getContentEvent', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
});

// 작품 별점 관련 store
export const contentScoreStore = defineStore('contentScore', {
	state: () => ({
		scoreInfo: null,
		alreadyScoreState: {
			isRegi: false,
			myScore: 1,
		},
	}),
	getters: {
		getRegistText() {
			if (this.alreadyScoreState.isRegi) {
				return '수정';
			} else {
				return '등록';
			}
		},
	},
	actions: {
		// 작품 별점 정보 조회
		getContentScore(idx) {
			const params = {
				idx,
			};
			ajax('content/contentScore', params)
				.then((response) => {
					this.scoreInfo = response.data.data;
					log_response('getContentScore', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 별점 등록
		insertContentScore(idx, score) {
			const params = {
				opus_no: idx,
				s_score: score,
			};
			ajax('content/insertScore', params)
				.then((response) => {
					contentModalStore().setModalActive('true');
					contentModalStore().setModalType('insert');
					this.getContentScore(idx);
					this.alreadyScoreState.isRegi = true;
					this.alreadyScoreState.myScore = score;
					log_response('insertContentScore', response.data);
				})
				.catch((error) => {
					console.log(error);
					contentModalStore().setModalActive('true');
					contentModalStore().setModalType('service');
				});
		},
		// 별점 수정
		modifyContentStore(idx, score) {
			const params = {
				opus_no: idx,
				s_score: score,
			};
			ajax('content/modifyScore', params)
				.then((response) => {
					contentModalStore().setModalActive('true');
					contentModalStore().setModalType('modify');
					this.getContentScore(idx);
					this.alreadyScoreState.isRegi = true;
					this.alreadyScoreState.myScore = score;
					log_response('modifyContentStore', response.data);
				})
				.catch((error) => {
					console.log(error);
					contentModalStore().setModalActive('true');
					contentModalStore().setModalType('service');
				});
		},

		// 이전에 별점을 등록한 전적이 있는지 확인
		checkAlreadyScoreState(idx) {
			const params = {
				opusNo: idx,
			};
			ajax('content/alreadyScoreState', params)
				.then((response) => {
					this.alreadyScoreState = response.data.data;
					log_response('checkAlreadyScoreState', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
});

// 모달창 출력 여부 store
export const contentModalStore = defineStore('contentModal', {
	state: () => ({
		modalActive: false, // 모달 활성화 여부
		modalType: null, // 로그인 모달, 서비스 이용 모달
	}),
	actions: {
		setModalActive(state) {
			this.modalActive = state;
		},
		setModalType(type) {
			/*
				로그인 필요 : login
				등록 완료 : insert
				서비스 이용 필요 : service
				이용약관 모달 : term
				개인정보취급방침 : privacy
			*/
			this.modalType = type;
		},
	},
});

// 오디오 플레이어 관련 store
export const contentPlayerStore = defineStore('contentPlayer', {
	state: () => ({
		idx: null, // 작품 번호
		playerVisible: false, // 팝업을 출력할 건지 정함 ( true 또는 false )
		playerPosition: 'top', // 어떤 위치에 팝업을 띄울건지 정함 ( top 또는 bottom )
		allEpisodes: [], // 해당 작품의 전체 에피소드 목록
		epiNum: 0, // 재생 시 기본으로 첫번째 에피소드를 재생하게 함
		savePrevEpiNum: 0, // 최근 재생된 에피소드 번호
		imgTab: false, // 플레이어 상단 삽화, 에피소드 탭 액티브 여부	true = 이미지 탭 활성화
		illustArr: [], // 에피소드 별 삽화 리스트
		illustIdx: 0, // 첫번째 삽화부터 보여주기 위해 생성
		playList: [],

		// 현재 선택한 에피소드의 정보
		playEpisodeInfo: {},
		playState: false, // 재생 여부
		audioPath: '', // 현재 재생할 파일 경로
		publicTime: 0,
		episodeDuration: 0,
		speed: 1,
		volume: 50,
		loop: true,
		timeBar: 0, // 사용자 타임바와 매칭
		contentThumbnail: '', // 작품 썸네일 경로(allEpisode )
		contentTitle: '',
	}),
	getters: {
		/*
		전체 대여 및 소장 가격
		getTotalProductPrice() {
			let totalProduct = 0;
			this.allEpisodes.forEach((epi) => {
				totalProduct += epi.product_price;
			});
			return totalProduct.toLocaleString();
		},
		getTotalRentPrice() {
			let totalRent = 0;
			this.allEpisodes.forEach((epi) => {
				totalRent += epi.rent_price;
			});
			return totalRent.toLocaleString();
		},
		회차 개수 체크
		회차가 아무것도 없다면 최상단 DOM에 항시 상주하는 오디오 플레이어의 플레이 리스트 항목을 로드하지 않는다.
		checkEpisodeCount() {
			// return this.allEpisodes.length > 0 ? true : false;
			return true;
		},
		*/
	},
	actions: {
		// store 작품번호 state 세팅
		setIdx(idx) {
			this.idx = idx;
		},
		// 플레이어에서 현재 재생중인 회차의 정보 초기화
		resetEpisodeInfo() {
			this.playEpisodeInfo = {};
			this.savePrevEpiNum = null;
			this.epiNum = 0;
		},
		// 오디오 태그와 매칭되는 플레이어 정보 초기화
		resetPlayInfo() {
			this.setPlayState(false);
			this.setTimeBar(0);
			this.setPublicTime(0);
			this.episodeDuration = 0;
			this.audioPath = '';
		},
		// 에러 발생 시 이전 회차 정보로 재설정
		resetPrevEpisode() {
			this.playEpisodeInfo = this.playList[this.savePrevEpiNum - 1];
			this.epiNum = this.savePrevEpiNum;
		},
		// 현재 조회 중인 작품의 에피소드 리스트 세팅
		setPlayList() {
			this.playList = this.allEpisodes;
			this.contentThumbnail = contentDetailInfoStore().contentInfo.thumb_path;
			this.contentTitle = contentDetailInfoStore().contentInfo.opus_title;
		},
		// 회차의 모든 에피소드 조회
		getAllEpisodes() {
			const params = {
				idx: this.idx,
				order: 'OLD',
			};
			ajax('content/allEpisodes', params).then((response) => {
				// 에피소드 전체 조회한 후 플레이어 관련 상태값 초기화
				this.allEpisodes = response.data.data;
				log_response('getAllEpisodes', response.data.data);
			});
		},
		// 첫 회차(무료) 조회
		getFirstEpisode() {
			const params = {
				idx: this.idx,
				order: 'OLD',
			};

			ajax('content/allEpisodes', params).then((response) => {
				this.allEpisodes = response.data.data.slice(0, 1);
				console.log(this.allEpisodes);
				log_response('getFirstEpisode', response.data.data);
			});
		},
		// 회차 리스트 중 선택한 회차의 정보 세팅 후 멤버쉽 체크까지 진행
		setPlayEpisodeInfo(epiNum, btnClickState) {
			// 회차 번호를 받아서 전체 에피소드 중 해당 인덱스의 값으로 설정
			// 음원 재생이 끝나 자동으로 다음 음원으로 넘어가는 것이 아닌 사용자가 직접 이전, 다음 음원 재생 버튼을 눌렀는지도 체크

			if (epiNum > this.playList.length) {
				if (this.loop || btnClickState) {
					setTimeout(() => {
						this.epiNum = 1;
						this.playEpisodeInfo = this.playList[this.epiNum - 1];
						this.checkMembership();
					}, 500);
				} else {
					alert('마지막 에피소드입니다.');
				}
			} else if (epiNum < 1) {
				if (this.loop || btnClickState) {
					setTimeout(() => {
						this.epiNum = this.allEpisodes.length;
						if (this.epiNum == this.savePrevEpiNum) {
							// alert('동일편');
							this.setPublicTime(0);
							return false;
						}
						this.playEpisodeInfo = this.playList[this.epiNum - 1];
						this.checkMembership();
					}, 500);
				} else {
					return;
				}
			} else {
				this.epiNum = epiNum;
				this.playEpisodeInfo = this.playList[epiNum - 1];
				this.checkMembership();
			}
		},
		// 권한 체크 및 에피소드 정보 GET
		checkMembership() {
			const params = {
				epi_idx: this.playEpisodeInfo.epi_seq,
			};

			// 해당 음원을 재생 할 수 있는 계정일 때
			// audioPath나 삽화 리스트 등을 저장한다
			ajax('content/checkMembership', params)
				.then((response) => {
					this.audioPath = response.data.data.EpisodeFile.file_path;
					this.episodeDuration = response.data.data.EpisodeFile.file_duration;
					// this.publicTime = 0;
					this.illustArr = response.data.data.ArtWorkFile;
					this.file_path = response.data.data.file_path;
					// this.illustIdx = 0;
					this.savePrevEpiNum = this.epiNum;
					log_response('checkMembership', response.data.data);
				})
				.catch((error) => {
					console.log(error.response.data);
					if (error.response.data.code === 'P001') {
						alert('멤버쉽에 가입되어있지 않습니다.');
						if (this.savePrevEpiNum != 0) {
							this.playEpisodeInfo = this.playList[this.savePrevEpiNum - 1];
							this.epiNum = this.savePrevEpiNum;
						} else {
							this.resetPlayInfo();
						}
					} else if (error.response.data.code === 'C004') {
						alert('파일 로드에 실패하였습니다.');
						if (this.savePrevEpiNum != 0) {
							// 정상 회차를 먼저 들은 후 다음회차에서 에러가 발생 시 이전 정상 회차로 되돌림
							this.resetPrevEpisode();
						} else {
							// 처음 실행 한 음원에 문제가 있을 경우 아예 플레이어 정보 초기화
							this.resetEpisodeInfo();
							this.resetPlayInfo();
						}
					}
				});
		},
		// 재생 속도 배율 조정
		setSpeed(speed) {
			this.speed = speed;
		},
		// 오디오 볼륨 조정
		setVolume(volume) {
			this.volume = volume;
		},
		// 플레이리스트 재생 가능한 항목들 전체 반복 여부
		setLoop(status) {
			this.loop = status;
		},
		// 현재 재생시간 받아와서 저장
		setPublicTime(time) {
			this.publicTime = time;
		},
		// 재생 여부 설정
		setPlayState(state) {
			this.playState = state;
		},
		// 사용자 타임 바 값 저장
		setTimeBar(time) {
			this.timeBar = time;
		},
	},
	// persist: {
	// 	enabled: true,
	// },
});
