import { defineStore } from 'pinia';
import ajax from '@/api/api-manager';
import jwt from '@/api/LocalStorage';

export const userInfoStore = defineStore('userInfo', {
	state: () => ({
		//스토어 내에 기본값 생성
		dormancy_modal: false,
		dormancy_clear_modal: false,
		find_id_modal: false,
		find_id_clear_modal: false,
		find_id_list: null,
		find_pw_modal: false,
		find_pw_clear_modal: false,
		advice_pw_modal: false,
		change_pw_clear_modal: false,
		Refund_modal: false,
		user_id: null,
		user_info_list: null,
		token: jwt.getToken(),
		naver_token: null,
		nickname: null,
		membership: null,
	}),
	getters: {},

	actions: {
		user_info() {
			ajax('user/user_info')
				.then((response) => {
					console.log(response);
					this.user_info_list = response.data.data;
				})
				.catch((err) => {
					console.log(err.response.data);
				});
		},

		user_membershipCheck() {
			//  유저 멤버십 여부 체크
			ajax('charge/season_ticket_value').then((response) => {
				console.log(`멤버쉽 : ${response.data.data}`);
				if (response.data.data) {
					this.membership = true;
				} else {
					this.membership = false;
				}
			});
		},
	},
});

export const signUpStateStore = defineStore('signUpInfo', {
	state: () => ({
		agreeTermState: false, // 약관 동의 여부 기본값 : false
		successSignUp: false, // 회원가입 성공 여부 기본값 : false (정상적으로 회원가입 시 true로 변경)
	}),
	persist: {
		enabled: true,
	},
});
