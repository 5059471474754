import axiosService from './index';

const test = () => {
	return axiosService.get(`/json/paging`);
};

const getImage = () => {
	return axiosService.get();
};

export default {
	test,
	getImage,
};
