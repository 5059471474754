import axiosService from '../index';

const user_info_modify = (parameters) => {
	return axiosService.put(`/user`, parameters);
};

const like_list = (parameters) => {
	return axiosService.get(`/mypage/fav/list/${parameters}`);
};
const delete_like_list = (parameters) => {
	return axiosService.delete(`/mypage/fav`, {
		params: { seq: parameters.get('seq') },
	});
};
const content_list = (parameters) => {
	return axiosService.get(`/mypage/buy/list/${parameters.get('page')}`, {
		params: { rantYn: parameters.get('rantYn') },
	});
};
const delete_content_list = (parameters) => {
	return axiosService.delete(`/mypage/buy`, {
		params: { idx: parameters.get('idx') },
	});
};

const play_list = (parameters) => {
	return axiosService.get(`/mypage/watch/list/${parameters.get('page')}`, {
		params: { order: parameters.get('order') },
	});
};
const delete_play_list = (parameters) => {
	return axiosService.delete(`/mypage/watch`, {
		params: { seq: parameters.get('seq') },
	});
};

const coupon_list = (parameters) => {
	return axiosService.get(`/coupon/use/${parameters}`);
};
const coupon_regi = (parameters) => {
	return axiosService.post(`/coupon`, parameters);
};
const coupon_content_list = (parameters) => {
	return axiosService.delete(`/coupon/${parameters.get('cNo')}`);
};

export default {
	user_info_modify,
	like_list,
	delete_like_list,
	content_list,
	delete_content_list,
	play_list,
	delete_play_list,
	coupon_list,
	coupon_regi,
	coupon_content_list,
};
