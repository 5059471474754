import moment from 'moment';

/* 
    날짜 값을 디자인에 맞게 포맷팅하여 반환하는 함수
*/

// 년. 월. 일
const YMD = (time) => {
	const ymd = moment(time);
	return ymd.format('YYYY. MM. DD');
};

// 분 초
const MS = (time) => {
	const ms = moment.duration(time, 'seconds');
	return `${ms.minutes()}분 ${ms.seconds()}초`;
};

// 시간 분 초
const HMS = (time) => {
	const hms = moment.duration(time, 'seconds');
	return `${hms.hours().toString().padStart(2, '0')} : ${hms.minutes().toString().padStart(2, '0')} : ${hms
		.seconds()
		.toString()
		.padStart(2, '0')}`;
};

export const convertTimeFormat = {
	YMD,
	MS,
	HMS,
};

/* 
    작품간의 랭킹을 계산하여 랭킹 상승 또는 하락에 대한 상태값을 반환
*/

// 이전 현재 랭킹 비교하여 변화여부 출력
export const calcRanking = (prev, current) => {
	const result = prev - current;
	if (result === 0) {
		return 'same';
	} else if (result > 0) {
		return 'up';
	} else {
		return 'down';
	}
	// return prev - current > -1 ? 1 : 0;
};

export const calcStep = (prev, current) => {
	const result = Math.abs(prev - current);
	return result === 0 ? '' : result;
};

/* 
    작품마다 장르 코드가 대문자 영문 2글자로 넘어올 때 국문으로 변환하여 반환
*/

const genreMap = new Map();

genreMap.set('TR', '스릴러');
genreMap.set('ML', '멜로');
genreMap.set('RC', '로맨틱코미디');
genreMap.set('AC', '액션');
genreMap.set('HR', '호러');

// API 응답값으로 받아오는 장르 코드는 두개의 대문자 알파벳으로 되어있음 따라서 한국어로 변환시켜주는 메소드
export const convertGenreCode = (genreCode) => {
	return genreMap.get(genreCode);
};
