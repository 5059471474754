import axiosService from '../index';

const signupDuplicate = (parameters) => {
	return axiosService.get(`/duplicate/id`, {
		params: { id: parameters },
	});
};
const emailAuth = (parameters) => {
	return axiosService.get(`/email/send/auth`, {
		params: { id: parameters },
	});
};
const subEmailDupe = (parameters) => {
	return axiosService.get(`/duplicate/sub/email`, {
		params: { email: parameters },
	});
};
const signup = (parameters) => {
	return axiosService.post(`/signup`, parameters, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
const signin = (parameters) => {
	return axiosService.post(`/login`, parameters);
};

const dormancy_code = (parameters) => {
	return axiosService.get(`/email/send/release`, {
		params: { id: parameters },
	});
};
const dormancy_release = (parameters) => {
	return axiosService.put(`/release/member`, parameters);
};

const find_id = (parameters) => {
	return axiosService.get(`/find/id`, {
		params: { email: parameters },
	});
};
const find_pw = (parameters) => {
	return axiosService.get(`/email/send/pw`, {
		params: { id: parameters },
	});
};
const modify_pw = (parameters) => {
	return axiosService.put(`/pw/auth`, parameters);
};
const advice_pw = () => {
	return axiosService.get(`/check/pw/date`);
};
const kakao_login = (parameters) => {
	return axiosService.get(`/sns/login`, {
		params: { id: parameters.get('id'), userType: parameters.get('userType') },
	});
};
const user_info = () => {
	return axiosService.get(`/user`);
};
const user_certify_info = () => {
	return axiosService.get(`/user/check/certifications`);
};
const user_certify = (params) => {
	return axiosService.post(`/user/certifications`, params);
};
// 회원 탈퇴
const secession = (params) => {
	return axiosService.put(`/user/secession`, null, { params });
};

export default {
	signupDuplicate,
	dormancy_code,
	advice_pw,
	emailAuth,
	signin,
	subEmailDupe,
	signup,
	dormancy_release,
	find_id,
	find_pw,
	modify_pw,
	kakao_login,
	user_info,
	user_certify,
	user_certify_info,
	secession,
};
