<template>
	<div class="player_header">
		<div class="player_thumb">
			<img alt="content thumbnail" :src="`${$image_host}/contents/${contentThumbnail}`" />
		</div>
		<div class="playing_tit_wrap">
			<h3>
				<span>{{ epiNum ? `[ep.${epiNum}]` : '회차를 선택해 주세요' }}</span
				>{{ playEpisodeInfo.epi_title }}
			</h3>
			<div class="sub_tit_wrap">
				<p>{{ contentTitle }}</p>
			</div>
		</div>
		<div class="set_speed_wrap">
			<button @click="visibleSpeedSelector = true" type="button" class="set_speed">{{ speed }}x</button>
			<div class="speed_select_box" v-show="visibleSpeedSelector">
				<div class="speed_select_wrap">
					<ul>
						<li
							@click="speed = item"
							:class="{ active: item === speed }"
							v-for="(item, index) in speedArr"
							:key="index"
						>
							{{ item }}x
						</li>

						<li @click="visibleSpeedSelector = false" class="close">닫기</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { contentPlayerStore } from '@/store/contentDetailStore';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';

const playerStore = contentPlayerStore();
const { playEpisodeInfo, playList, contentThumbnail, speed, epiNum, contentTitle } = storeToRefs(playerStore);

watch(epiNum, (newData) => {
	console.log(newData);
});
// 재생 속도 리스트
const speedArr = ref([0.5, 1, 1.2, 1.5, 2]);
const visibleSpeedSelector = ref(false);
</script>

<style scoped>
#realAudio {
	display: none;
}
</style>
