import axiosService from '../index';

// 작품 상세보기 페이지에서 작품 상세 정보, 별점, 댓글 등.. 작품의 자세한 정보들을 출력하기 위한 API 모듈

// 작품 상세정보 조회
const contentDetail = (params) => {
	return axiosService.get(`/audio/${params.idx}`);
};

// 작품 회차 리스트 조회
const episodeList = (params) => {
	return axiosService.get(`/audio/${params.idx}/epi/${params.page}`, { params });
};

// 작품 전체 에피소드 조회
const allEpisodes = (params) => {
	return axiosService.get(`/audio/${params.idx}/epi`, { params });
};

// 작품 댓글 리스트 조회
const commentList = (params) => {
	return axiosService.get(`/opus/reply/${params.page}`, { params });
};

// 작품 댓글 등록
const insertComment = (params) => {
	return axiosService.post(`/opus/reply`, null, { params });
};

// 작품 댓글 삭제
const deleteComment = (params) => {
	return axiosService.delete(`/opus/reply`, { params });
};

// 작품 별점 조회
const contentScore = (params) => {
	return axiosService.get(`/opus/avg/score/${params.idx}`);
};

// 작품 별점 등록한 적이 있는지 체크
const alreadyScoreState = (params) => {
	return axiosService.get(`/opus/score/state`, { params });
};

// 작품 별점 등록
const insertScore = (params) => {
	return axiosService.post(`/opus/score`, null, { params });
};

// 작품 별점 수정
const modifyScore = (params) => {
	return axiosService.put(`/opus/score`, null, { params });
};

// 작품 이벤트 조회
const contentEvent = (params) => {
	return axiosService.get(`/audio/${params.idx}/event`);
};

// 작품 좋아요 여부 조회
const checkFavorite = (params) => {
	return axiosService.get(`/audio/favorite/${params.idx}`);
};

// 작품 좋아요 추가
const registFavorite = (params) => {
	return axiosService.post(`/audio/favorite/${params.opus_no}`);
};

// 작품 좋아요 취소
const cancelFavorite = (params) => {
	return axiosService.delete(`/audio/favorite/${params.opus_no}`);
};

// 회차 관람이 가능한지 멤버쉽 체크
const checkMembership = (params) => {
	return axiosService.get(`/audio/viewer/${params.epi_idx}`);
};

export default {
	contentDetail,
	episodeList,
	allEpisodes,
	commentList,
	insertComment,
	deleteComment,
	contentScore,
	insertScore,
	alreadyScoreState,
	modifyScore,
	contentEvent,
	checkFavorite,
	registFavorite,
	cancelFavorite,
	checkMembership,
};
