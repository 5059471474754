<template>
	<div class="player_bg_wrap">
		<div class="player_wrap">
			<div class="window_head">
				<div class="head_icon_wrap">
					<div class="head_circle">
						<a @click="playerPosition = 'bottom'" href="javascript:void(0);"
							><i class="bx bx-down-arrow-alt"></i
						></a>
					</div>
					<div class="head_circle">
						<a @click="closePlayer" href="javascript:void(0);"><i class="bx bx-x"></i></a>
					</div>
				</div>
			</div>
			<div class="player_body_wrap">
				<AudioTab></AudioTab>
				<AudioHead :epiNum="epiNum"></AudioHead>
				<EpisodeList></EpisodeList>
				<AudioPlayer></AudioPlayer>
			</div>
		</div>
	</div>
</template>

<script setup>
import { contentPlayerStore } from '@/store/contentDetailStore';
import { storeToRefs } from 'pinia';
import AudioPlayer from './top/AudioPlayer.vue';
import EpisodeList from './top/EpisodeList.vue';
import AudioTab from './top/AudioTab.vue';
import AudioHead from './top/AudioHead.vue';

// player 종료 및 위치 변경 + hidden audio tag 동기화
const playerStore = contentPlayerStore();
const { playerVisible, playerPosition, epiNum } = storeToRefs(playerStore);

// 플레이어 종료
const closePlayer = () => {
	if (confirm('플레이어를 종료하시겠습니까?')) {
		playerVisible.value = false;
		playerStore.resetPlayInfo();
	}
};
</script>

<style></style>
