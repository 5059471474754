import axiosService from '../index';

const noti_list = (parameters) => {
	return axiosService.get(`/noti/list/${parameters}`);
};

const FAQ_list = (parameters) => {
	return axiosService.get(`/faq/list/${parameters.get('id')}`, {
		params: { bType: parameters.get('bType') },
	});
};

export default {
	noti_list,
	FAQ_list,
};
