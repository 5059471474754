import axiosService from '../index';

const charge_list = () => {
	return axiosService.get(`/charge/item`);
};

const season_ticket_value = () => {
	return axiosService.get(`/user/membership`);
};

const inisis_pay = (parameters) => {
	return axiosService.post(`/imp/membership/billings`, {
		params: {
			amount: parameters.amount,
			custom_data: parameters.custom_data,
			customer_uid: parameters.customer_uid,
			name: parameters.name,
		},
	});
};
const use_list = (parameters) => {
	return axiosService.get(`/mypage/membership/history/${parameters.get('page')}`);
};
const use_current_plan = () => {
	return axiosService.get(`/mypage/use/membership`);
};
const use_plan = () => {
	return axiosService.get(`/imp/membership/cancel`);
};
const use_plan_cancle = () => {
	return axiosService.post(`/imp/membership/cancel`);
};

export default { charge_list, inisis_pay, season_ticket_value, use_list, use_current_plan, use_plan, use_plan_cancle };
