// 기능에 따라 파일별로 나눈 API 모듈 임포트하기
import common from './common.js';
import user from './api_modules/user';
import home from './api_modules/home';
import sub from './api_modules/sub';
import content from './api_modules/content';
import mypage from './api_modules/mypage';
import charge from './api_modules/charge';
import servicecenter from './api_modules/servicecenter';

const modules = {
	// 임포트한 모듈 삽입
	common,
	user,
	home,
	sub,
	content,
	mypage,
	servicecenter,
	charge,
};

export default function (pathString, parameters) {
	let targetAPI = modules;
	const paths = pathString.split('/');
	console.log({ paths, ...parameters });

	// 예를 들어 pathString으로 'user/signup' 이라는 문자열이 들어온다고 가정 시
	// 최종적으로 targetAPI에는 user객체의 signup 함수가 들어가게 된다.
	paths.forEach((key) => {
		// console.log(key);
		targetAPI = targetAPI[key];
		// console.log(targetAPI);
	});

	if (typeof targetAPI == 'function') {
		return targetAPI(parameters);
	}

	return Promise.reject(`${paths.slice(-1)[0]} <- 해당 API가 존재하지 않습니다.`);
}
