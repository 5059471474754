<template>
	<div class="wrapper">
		<Header v-if="getHeaderVisibleState"></Header>
		<div :class="[getWrapClass, { hide_770: playerVisible && playerPosition == 'top' }]">
			<router-view v-slot="{ Component }">
				<transition name="fade" mode="out-in" appear>
					<component :is="Component" />
				</transition>
			</router-view>
		</div>
		<Footer v-if="getFooterVisibleState"></Footer>
		<RealAudioComponent></RealAudioComponent>
		<TopPlayer v-if="playerVisible && playerPosition === 'top'"></TopPlayer>
		<BottomPlayer v-if="playerVisible && playerPosition === 'bottom'"></BottomPlayer>
	</div>
</template>

<script setup>
import Header from './shared-components/Header.vue';
import Footer from './shared-components/Footer.vue';
import { useRoute } from 'vue-router';

import { computed, ref, watch } from 'vue';

import RealAudioComponent from '@/shared-components/RealAudioComponent.vue';
import TopPlayer from '@/components/content-player/TopPlayer.vue';
import BottomPlayer from '@/components/content-player/BottomPlayer.vue';
import { contentPlayerStore } from './store/contentDetailStore';
import { storeToRefs } from 'pinia';

// route instance
const route = useRoute();

// router meta filed
const getWrapClass = computed(() => {
	if (route.meta.wrap === 'sub') {
		return 'sub_body_wrap';
	} else {
		return 'body_wrap';
	}
});

// 헤더 및 푸터를 보여주는 페이지인지 아닌 지 판별
// const headerVisible = ref(true);
// const footerVisible = ref(true);
const getHeaderVisibleState = computed(() => {
	if (route.meta.header === 'no') {
		return false;
	} else {
		return true;
	}
});

const getFooterVisibleState = computed(() => {
	if (route.meta.footer === 'no') {
		return false;
	} else {
		return true;
	}
});
// getHeaderAndFooterVisibleState();

// playerInfo store setting
const playerStore = contentPlayerStore();
const { playerVisible, playerPosition, playState } = storeToRefs(playerStore);

// 플레이어 팝업 창 종료 시
watch(playerVisible, (newData) => {
	if (!newData) playState.value = false;
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2? family= Noto+Sans+KR & display=swap');
@import './assets/css/common.css';
@import './assets/css/style.css';
@import './assets/css/sub.css';

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
	/* overflow: hidden; */
}

.fade-enter-active,
.fade-leave-active {
	transition-property: opacity;
	transition-duration: 0.2s;
}

.fade-enter-active {
	transition-delay: 0.2s;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
