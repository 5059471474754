import axios from 'axios';
import jwt from '@/api/LocalStorage';
const ID_TOKEN_KEY = 'id_token';
// import { userInfoStore } from '@/store/UserStore';

// const store = userInfoStore();
// pinia 사용시 맡애 에러 발생
// getActivePinia was called with no active Pinia. Did you forget to install pinia?
// const pinia = createPinia()
// app.use(pinia)

// console.log(process.env);
const axiosService = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
});

// Add a request interceptor
axiosService.interceptors.request.use(
	function (config) {
		if (jwt.getToken(ID_TOKEN_KEY)) {
			config.headers.Authorization = `Bearer ${jwt.getToken(ID_TOKEN_KEY)}`;
		}
		// Do something before request is sent
		// 요청 보내기 전에 제어할 부분
		return config;
	},
	function (error) {
		// Do something with request error
		// 요청 시 에러 처리
		return Promise.reject(error);
	},
);

// Add a response interceptor
axiosService.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		// console.log(response.data.data);
		return response;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (error.response.data.status === 403) {
			switch (error.response.data.code) {
				case 'C100':
					// alert('로그인이 만료되었습니다. 재로그인 해주세요');
					jwt.destroyToken(ID_TOKEN_KEY);
					jwt.destroyToken('id');
					window.location.href = '/login';
					break;
				case 'M000':
					alert('로그인 후 진행해주세요');
					break;
				case 'P001':
					alert('멤버쉽 권한이 존재하지 않습니다.');
					break;
			}
		} else if (error.response.data.status === 400) {
			switch (error.response.data.code) {
				case 'C001':
					alert('해당 계정을 찾을 수 없습니다.');
					break;
			}
		}
		return Promise.reject(error);
	},
);

export default axiosService;
